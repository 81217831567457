import * as React from 'react';
import moment from 'moment';

import {
    DynamicFieldSet,
    DatePicker,
    Input,
    Select,
    TextArea,
    ResetButton,
} from 'common/components/deprecated/form';
import { SubmitButton } from 'common/components';
import {
    formatSupplierIdRequest,
    formatIndividualExecutiveRequest,
    formatPartnerIdRequest,
    formatAssessorsRequest,
    formatPrioritiesRequest,
    formatPriorityToState,
    checkIntegerConfirmation,
    checkDecimalPlaceConfirmation,
    formatInvitePendingInvididualExecutiveRequest,
} from 'common/helpers/utils';
import { uniqueBenefitTooltips } from 'common/tooltips/uniqueBenefitTooltips';
import { CreateBenefitContainerProps } from 'features/create/containers/CreateBenefitContainer';
import { notificationText, showNotification, Notification } from 'common/helpers/notifications';
import { dateFormat, notArchivedFilterParam } from 'config/config';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField, AutocompleteData } from 'features/pagination/models/paginationModel';
import { benefitMethod } from 'features/contract/models/contractModels';
import { EditStatusExplanation } from 'features/benefit/components/EditStatusExplanation';
import { SupplierForm } from 'features/create/components/SupplierForm';
import { DeliveryPartnerForm } from 'features/create/components/DeliveryPartnerForm';
import { Organisation } from 'features/organisation/models/organisationModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { BenefitRole, BenefitPriority } from 'features/benefit/models/benefitModels';
import { Antd3Form, Antd3FormProps, StyledAntd3Form } from 'common/components/deprecated/antd3';

import { PrioritiesForm } from './PrioritiesForm';
import { DynamicUpstreamClientFieldSet } from 'common/components/deprecated/form/DynamicUpstreamClientFieldSet';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import { formatDate } from 'features/upload/helpers';

export interface DateObject {
    title: string;
    date: moment.Moment;
    id: number;
    isStart: boolean;
    isFinal: boolean;
}

export interface FormList {
    [id: string]: JSX.Element;
}

interface CreateBenefitState {
    priorities: BenefitPriority[];
    supplier?: Organisation;
    partners: Organisation[];
    individual_suppliers: AutocompleteData[];
    individual_partners: AutocompleteData[];
    new_executive_users: InviteByEmailWithRole[];
    invite_pending_supplier_users: AutocompleteData[];
    invite_pending_partner_users: AutocompleteData[];
}

type Props = CreateBenefitContainerProps & Antd3FormProps;

class CreateBenefit extends React.Component<Props, CreateBenefitState> {
    public state: CreateBenefitState = {
        priorities: [],
        supplier: undefined,
        partners: [],
        individual_suppliers: [],
        individual_partners: [],
        new_executive_users: [],
        invite_pending_supplier_users: [],
        invite_pending_partner_users: [],
    };

    private selectSupplierOrganisation = (supplier: Organisation) => {
        if (this.state.partners.find((item) => item.id == supplier.id)) {
            showNotification({
                text: 'This organisation has already been selected as a Delivery Partner.',
            });
            return;
        }
        this.setState({ supplier });
        this.setState({ individual_suppliers: [] });
        this.setState({ invite_pending_supplier_users: [] });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) => item.role !== BenefitRole.SUPPLIER,
            ),
        });
    };

    private deleteSupplierOrganisation = (_: number) => {
        this.setState({ supplier: undefined });
        this.setState({ individual_suppliers: [] });
        this.setState({ invite_pending_supplier_users: [] });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) => item.role !== BenefitRole.SUPPLIER,
            ),
        });
    };

    private selectSupplierUser = (supplier: AutocompleteData) => {
        if (this.state.individual_suppliers.find((item) => item!.id == supplier!.id)) {
            showNotification({ text: "You've already selected this Supplier user." });
            return;
        }
        this.setState({ individual_suppliers: [...this.state.individual_suppliers, supplier] });
    };

    private deleteSupplierUser = (userId: number) => {
        this.setState({
            individual_suppliers: this.state.individual_suppliers.filter(
                (item) => item!.id !== userId,
            ),
        });
    };

    private selectInvitePendingSupplierUser = (supplier: AutocompleteData) => {
        if (this.state.invite_pending_supplier_users.find((item) => item.id == supplier.id)) {
            showNotification({ text: "You've already selected this supplier user." });
            return;
        }
        this.setState({
            invite_pending_supplier_users: [...this.state.invite_pending_supplier_users, supplier],
        });
    };

    private deleteInvitePendingSupplierUser = (userId: number) => {
        this.setState({
            invite_pending_supplier_users: this.state.invite_pending_supplier_users.filter(
                (item) => item.id !== userId,
            ),
        });
    };

    private selectDeliveryPartnerOrganisation = (selectedPartner: Organisation) => {
        if (this.state.partners.find((item) => item.id == selectedPartner.id)) {
            showNotification({ text: "You've already selected this Delivery Partner." });
            return;
        }
        if (this.state.supplier && this.state.supplier.id == selectedPartner.id) {
            showNotification({
                text: 'This organisation has already been selected as a Supplier.',
            });
            return;
        }
        this.setState({ partners: [...this.state.partners, selectedPartner] });
    };

    private deleteDeliveryPartner = (partnerId: number) => {
        this.setState({
            partners: this.state.partners.filter((item) => item.id !== partnerId),
        });
        this.setState({
            individual_partners: this.state.individual_partners.filter(
                (item) => item.organisation !== partnerId,
            ),
        });
        this.setState({
            invite_pending_partner_users: this.state.invite_pending_partner_users.filter(
                (item) => item.organisation !== partnerId,
            ),
        });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) =>
                    item.organisationId !== partnerId && item.role !== BenefitRole.DELIVERY_PARTNER,
            ),
        });
    };

    private selectDeliveryPartnerUser = (selectedExecutive: AutocompleteData) => {
        if (
            this.state.individual_partners.find(
                (item) =>
                    item.id == selectedExecutive.id &&
                    item.organisation == selectedExecutive.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.setState({
            individual_partners: [...this.state.individual_partners, selectedExecutive],
        });
    };

    private deletePartnerUser = (executiveId: number) => {
        this.setState({
            individual_partners: this.state.individual_partners.filter(
                (item) => item.id !== executiveId,
            ),
        });
    };

    private selectInvitePendingPartnerUser = (selectedExecutive: AutocompleteData) => {
        if (
            this.state.invite_pending_partner_users.find(
                (item) =>
                    item.id == selectedExecutive.id &&
                    item.organisation == selectedExecutive.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.setState({
            invite_pending_partner_users: [
                ...this.state.invite_pending_partner_users,
                selectedExecutive,
            ],
        });
    };

    private deleteInvitePendingPartnerUser = (executiveId: number) => {
        this.setState({
            invite_pending_partner_users: this.state.invite_pending_partner_users.filter(
                (item) => item.id !== executiveId,
            ),
        });
    };

    private selectNewExecutiveUser = (employeeData: InviteByEmailWithRole) => {
        this.setState({
            new_executive_users: [...this.state.new_executive_users, employeeData],
        });
    };

    private deleteNewExecutiveUser = (employeeData: InviteByEmailWithRole) => {
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (employee) => employee !== employeeData,
            ),
        });
    };

    private selectNewPriority = (priorityData: BenefitPriorities) => {
        const formattedPriority = formatPriorityToState(priorityData);

        this.setState((prevState) => ({
            ...prevState,
            priorities: [...prevState.priorities, formattedPriority],
        }));
    };

    private deletePriority = (priorityId: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.filter((item) => item.id !== Number(priorityId)),
        }));
    };

    private changePriorityValue = (priorityId: number, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.map((priority) =>
                priority.id === priorityId ? { ...priority, value } : priority,
            ),
        }));
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.props.form.validateFields((error, { assessors, contract, ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            const deliveryDates: DateObject[] =
                values.deliveryDates && Object.values(values.deliveryDates);

            const dates = deliveryDates && deliveryDates.filter(({ date }) => date);

            const partners =
                this.state.partners === [] ? [] : formatPartnerIdRequest(this.state.partners);

            const suppliers = this.state.supplier
                ? formatSupplierIdRequest(this.state.supplier.id)
                : [];

            const individualSuppliers =
                this.state.individual_suppliers === []
                    ? []
                    : formatIndividualExecutiveRequest(this.state.individual_suppliers);

            const individualPartners =
                this.state.individual_partners === []
                    ? []
                    : formatIndividualExecutiveRequest(this.state.individual_partners);

            const individualInvitePendingSuppliers =
                this.state.invite_pending_supplier_users === []
                    ? []
                    : formatInvitePendingInvididualExecutiveRequest(
                          this.state.invite_pending_supplier_users,
                          BenefitRole.SUPPLIER,
                      );

            const individualInvitePendingPartners =
                this.state.invite_pending_partner_users === []
                    ? []
                    : formatInvitePendingInvididualExecutiveRequest(
                          this.state.invite_pending_partner_users,
                          BenefitRole.DELIVERY_PARTNER,
                      );

            const new_executive_users = this.state.new_executive_users;

            this.state.partners.forEach(
                (partner) => delete values[`individual_partners-${partner.id}`],
            );

            if (contract) {
                return this.props.createBenefitRequest({
                    ...values,
                    reported_on: values.reported_on && formatDate(values.reported_on),
                    contract,
                    assessors: formatAssessorsRequest(assessors, this.props.organisation),
                    suppliers,
                    partners,
                    deliveryDates:
                        (dates &&
                            dates.map(({ date, ...rest }: DateObject) => ({
                                ...rest,
                                date: date.format(dateFormat),
                            }))) ||
                        [],
                    individual_suppliers: individualSuppliers,
                    individual_partners: individualPartners,
                    invite_pending_individual_users: individualInvitePendingSuppliers.concat(
                        individualInvitePendingPartners,
                    ),
                    new_executive_users,
                    priorities: formatPrioritiesRequest(this.state.priorities),
                });
            }
            return showNotification({
                type: Notification.Error,
                text: 'Please choose a project',
            });
        });
    };

    public render(): JSX.Element {
        const { form } = this.props;

        const contractId = this.props.contractId
            ? Number.parseInt(this.props.contractId, 10)
            : undefined;

        const benefitStatus = form.getFieldValue('status');

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Create Unique Benefit" bigHeader>
                <ResetButton form={form} />
                <AutocompleteSelect
                    form={form}
                    id="contract"
                    label="Project Title / Reference Number"
                    initialValue={contractId}
                    autocompleteField={AutocompleteField.Contract}
                    required
                    tooltip={uniqueBenefitTooltips.ContractTitle}
                    dataTestAttribute="contract-select"
                />
                <Input
                    form={form}
                    id="outcome"
                    placeholder="Outcome"
                    tooltip={uniqueBenefitTooltips.Outcome}
                />
                <TextArea
                    form={form}
                    id="description"
                    placeholder="Benefit Description"
                    required={false}
                    tooltip={uniqueBenefitTooltips.BenefitDescription}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitCategory}
                    id="category"
                    placeholder="Benefit Category"
                    notFoundContent="No benefit categories found"
                    searchParams={notArchivedFilterParam}
                    required
                    tooltip={uniqueBenefitTooltips.BenefitCategory}
                />
                <Select
                    form={form}
                    id="method"
                    placeholder="Benefit Model"
                    options={benefitMethod}
                    required={false}
                    allowClear
                    tooltip={uniqueBenefitTooltips.BenefitModel}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Location}
                    id="location"
                    placeholder="Benefit Location"
                    notFoundContent="No benefit location found"
                    searchParams={notArchivedFilterParam}
                    tooltip={uniqueBenefitTooltips.BenefitLocation}
                />
                <Input
                    form={form}
                    id="points"
                    placeholder="Benefit Points"
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={uniqueBenefitTooltips.NumberOfPoints}
                />
                <Input
                    form={form}
                    id="value"
                    placeholder="Benefit Value"
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={uniqueBenefitTooltips.BenefitValue}
                />
                <Input
                    form={form}
                    id="emissionSavings"
                    placeholder="Emissions Savings"
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={uniqueBenefitTooltips.EmissionsSavings}
                />
                <Input
                    form={form}
                    id="quantity"
                    placeholder="Quantity"
                    type="number"
                    validator={checkIntegerConfirmation}
                    tooltip={uniqueBenefitTooltips.Quantity}
                />
                <PrioritiesForm
                    form={form}
                    selectedPriorities={this.state.priorities}
                    selectNewPriority={this.selectNewPriority}
                    deletePriority={this.deletePriority}
                    changePriorityValue={this.changePriorityValue}
                    autocompleteField={AutocompleteField.BenefitPriorities}
                    id="priorities"
                    placeholder="Benefit Priorities"
                    notFoundContent="No benefit priorities found"
                    tooltip={uniqueBenefitTooltips.BenefitPriorities}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    id="assessors"
                    placeholder="Contributor (Please read tooltip before use)"
                    notFoundContent="No employees found"
                    multiple
                    searchParams={notArchivedFilterParam}
                    tooltip={uniqueBenefitTooltips.ProjectManagers}
                />
                <SupplierForm
                    form={form}
                    newSupplierUsers={this.state.new_executive_users}
                    selectNewSupplierUser={this.selectNewExecutiveUser}
                    deleteNewSupplierUser={this.deleteNewExecutiveUser}
                    selectedSupplier={this.state.supplier}
                    selectSupplierOrganisation={this.selectSupplierOrganisation}
                    deleteSupplierOrganisation={this.deleteSupplierOrganisation}
                    selectedSupplierUsers={this.state.individual_suppliers}
                    selectSupplierUser={this.selectSupplierUser}
                    deleteSupplierUser={this.deleteSupplierUser}
                    selectedInvitePendingUsers={this.state.invite_pending_supplier_users}
                    selectInvitePendingSupplierUser={this.selectInvitePendingSupplierUser}
                    deleteInvitePendingSupplierUser={this.deleteInvitePendingSupplierUser}
                    tooltip={uniqueBenefitTooltips.Supplier}
                />
                <DeliveryPartnerForm
                    form={this.props.form}
                    newPartnerUsers={this.state.new_executive_users}
                    deleteNewPartnerUser={this.deleteNewExecutiveUser}
                    selectNewPartnerUser={this.selectNewExecutiveUser}
                    selectedPartners={this.state.partners}
                    selectPartnerOrganisation={this.selectDeliveryPartnerOrganisation}
                    deletePartner={this.deleteDeliveryPartner}
                    selectedPartnerUsers={this.state.individual_partners}
                    selectPartnerUser={this.selectDeliveryPartnerUser}
                    deletePartnerUser={this.deletePartnerUser}
                    selectedInvitePendingUsers={this.state.invite_pending_partner_users}
                    selectInvitePendingPartnerUser={this.selectInvitePendingPartnerUser}
                    deleteInvitePendingPartnerUser={this.deleteInvitePendingPartnerUser}
                    tooltip={uniqueBenefitTooltips.DeliveryPartners}
                />
                <DatePicker
                    form={form}
                    id="startDate"
                    placeholder="Start Date"
                    required={false}
                    tooltip={uniqueBenefitTooltips.StartDate}
                />
                <DynamicFieldSet
                    fieldSetId="deliveryDates"
                    fieldType="deliveryDate"
                    label="Interim Delivery Date"
                    placeholder="Interim Delivery Date"
                    form={form}
                    tooltip={uniqueBenefitTooltips.InterimDeliveryDate}
                />
                <DatePicker
                    form={form}
                    id="finalDeliveryDate"
                    placeholder="Benefit Completion Date"
                    tooltip={uniqueBenefitTooltips.FinalDeliveryDate}
                />
                <DatePicker
                    form={form}
                    id="reported_on"
                    label="Benefit Reporting Date"
                    tooltip={templatedBenefitTooltips.reportedOn}
                    required={false}
                />
                <EditStatusExplanation
                    form={form}
                    id="status"
                    benefitStatus={benefitStatus}
                    defaultValueForNewBenefit={true}
                    tooltip={uniqueBenefitTooltips.BenefitStatus}
                />
                <SubmitButton value="Create Unique Benefit" />
            </StyledAntd3Form>
        );
    }
}

export const CreateBenefitForm = Antd3Form.create({})(CreateBenefit);
